export function importJs(channel: string, data: any) {
  return new Promise((resolve, reject) => {
    const windowTemp = window as any;
    console.log(channel);
    switch (channel) {
      case "BEST_PAY":
      case "BESTPAY":
        const params = {
          merchantNo: data.merchantNo, //商户号
          institutionCode: data.institutionCode, //机构号
          institutionType: data.institutionType, //类型
          signType: data.signType, //签名方式
          sign: data.sign, //签名
          platform: data.platform, //平台默认值
          tradeType: data.tradeType, //收单类交易默认值
          outTradeNo: data.outTradeNo, //商户订单号
          tradeNo: data.tradeNo, //翼支付交易号
          tradeAmt: data.tradeAmt, //订单金额，单位分
          tradeDesc: data.tradeDesc, //订单描述
        };
        console.log("唤起收银台传参：", params);
        windowTemp.BestpayHtml5.Payment.payForSimpleCheckout(
          params,
          function (obj: any) {
            resolve(obj);
          },
          function (obj: any) {
            resolve(obj);
          },
          function (err: any) {
            console.log(err);
            // alert(err.message || err || "网络错误，请检查网络连接");
            resolve(err);
          }
        );
        break;
      case "other":
        reject({});
        break;
    }
  });
}
